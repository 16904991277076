.html5qr-code-full-region {
    border-radius: 5px;
}
.html5qr-code-full-region #html5-qrcode-select-camera {
    border: 0.5px solid !important;
    display: inline-flex;
    flex-shrink: 0;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    -webkit-appearance: none;
    appearance: none;
    height: 3rem;
    margin-left: 10px;
    padding-left: 0.5rem;
    padding-right: 1.25rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    line-height: 2;
    min-height: 3rem;
    border-width: 1px;
    border-color: hsl(var(--bc) / var(--tw-border-opacity));
    --tw-border-opacity: 0;
    --tw-bg-opacity: 1;
    background-color: hsl(var(--b1) / var(--tw-bg-opacity));
    font-weight: 600;
    border-radius: var(--rounded-btn, 0.5rem);
    background-image: linear-gradient(45deg, transparent 50%, currentColor 50%),
        linear-gradient(135deg, currentColor 50%, transparent 50%);
    background-position:
        calc(100% - 10px) calc(1px + 50%),
        calc(100% - 6px) calc(1px + 50%);
    background-size:
        4px 4px,
        4px 4px;
    background-repeat: no-repeat;
}
div#html5qr-code-full-region__dashboard_section_csr > span {
    display: block;
    margin: 0px !important;
}
div#html5qr-code-full-region__header_message {
    border-top: 0px !important;
}
.html5qr-code-full-region > div > div {
    border-top: 0px;
    color: hsl(var(--nc) / var(--tw-text-opacity));
}
.html5qr-code-full-region #qr-shaded-region {
    position: absolute;
    border-color: rgba(0, 0, 0, 0.48);
    border-style: solid;

    box-sizing: border-box;
    inset: 0px;
    border-top: auto !important;
    margin: auto;
    box-shadow: 1px;
}

button.html5-qrcode-element {
    margin: 0.5rem;
    display: inline-flex;
    flex-shrink: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    border-color: transparent;
    border-color: hsl(var(--n) / var(--tw-border-opacity));
    text-align: center;
    transition-property:
        color,
        background-color,
        border-color,
        text-decoration-color,
        fill,
        stroke,
        opacity,
        box-shadow,
        transform,
        filter,
        -webkit-backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke,
        opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property:
        color,
        background-color,
        border-color,
        text-decoration-color,
        fill,
        stroke,
        opacity,
        box-shadow,
        transform,
        filter,
        backdrop-filter,
        -webkit-backdrop-filter;
    transition-duration: 200ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    border-radius: var(--rounded-btn, 0.5rem);
    height: 3rem;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    line-height: 1em;
    min-height: 3rem;
    font-weight: 600;
    text-transform: uppercase;
    text-transform: var(--btn-text-case, uppercase);
    text-decoration-line: none;
    border-width: var(--border-btn, 1px);

    --tw-border-opacity: 1;
    --tw-bg-opacity: 1;
    background-color: hsl(var(--n) / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: hsl(var(--nc) / var(--tw-text-opacity));
}
.html5qr-code-full-region img {
    margin: auto;
}
/* div.html5qr-code-full-region {
  border: none !important;
}

.html5qr-code-full-region div{
  border: none !important;
  font-size: 1rem !important;
}
 */
